








































@import '~@/assets/styles/helpers/_variables.scss';
.acesso-rapido-boxs {
  display: flex;

  &__box {
    width: 100px;
    height: 100px;
    background-color: #f5f5f5;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-decoration: none;

    &:hover {
      .acesso-rapido-boxs__box_icon {
        color: #FFF;
        border-color: #FFF;

        .v-icon {
          color: #FFF;
        }
      }

      .acesso-rapido-boxs__box_text {
        color: #FFF;
      }
    }


    &_receita:hover {
      background-color: $colorDanger;
    }

    &_despesas:hover {
      background-color: $colorGreenDarken;
    }

    &_transferencia:hover {
      background-color: $colorAccent;
    }

    &_icon {
      border: 2px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-bottom: 5px;
    }

    &_icon_transferencia {
      border-color: $colorAccent;
      .v-icon {
        color: $colorAccent;
      }
    }
    &_icon_receita {
      border-color: $colorDanger;
      .v-icon {
        color: $colorDanger;
      }
    }
    &_icon_despesas {
      border-color: $colorGreenDarken;
      .v-icon {
        color: $colorGreenDarken;
      }
    }

    &_text {
      color: #555;
      text-transform: uppercase;
    }
    &_text_ {
      text-transform: uppercase;
    }
    &_text {
      text-transform: uppercase;
    }
    &_text {
      text-transform: uppercase;
    }
  }
}
